import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user; // Set user data
      state.token = action.payload.token; // Set user token
      state.isAuthenticated = true; // Set authenticated to true
      // Store user data and token in sessionStorage
      sessionStorage.setItem('user', JSON.stringify(action.payload.user));
      sessionStorage.setItem('token', action.payload.token);
    },
    logout(state) {
      state.user = null; // Clear user data
      state.token = null; // Clear token
      state.isAuthenticated = false; // Set authenticated to false
      // Clear user data and token from sessionStorage
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
    },
    initializeAuth(state) {
      const user = sessionStorage.getItem('user');
      const token = sessionStorage.getItem('token');
      if (user && token) {
        state.user = JSON.parse(user);
        state.token = token;
        state.isAuthenticated = true;
      }
    },
  },
});

export const authUser = (state) => state.auth.user; 
export const bearerToken = (state) => state.auth.token; 

// Define a selector to get the authentication status
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;

// Export actions
export const { login, logout, initializeAuth } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
