import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://homestay.localnest.in/api',
  timeout: 500000, 
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

const apiService = {
  get: (url, params = {}, headers = {}, withToken = false) => {
    const token = localStorage.getItem('token');
    if (withToken && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return axiosInstance.get(url, { params, headers });
  },

  post: (url, body = {}, headers = {}, withToken = false) => {
    const token = localStorage.getItem('token');
    if (withToken && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return axiosInstance.post(url, body, { headers });
  },

  put: (url, body = {}, headers = {}, withToken = false) => {
    const token = localStorage.getItem('token');
    if (withToken && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return axiosInstance.put(url, body, { headers });
  },

  delete: (url, params = {}, headers = {}, withToken = false) => {
    const token = localStorage.getItem('token');
    if (withToken && token) {
      headers.Authorization = `Bearer ${token}`;
    }
    return axiosInstance.delete(url, { params, headers });
  },
};

export default apiService;
