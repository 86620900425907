import ImageCard from '../image-card/image-card';
import ImageCardSkeleton from '../image-card-skeleton/image-card-skeleton';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { Navigation, Pagination } from 'swiper/modules'; // Import Navigation & Pagination modules
import {ArrowLeftIcon,ArrowRightIcon}  from "@heroicons/react/24/solid"

const PopularLocations = (props) => {
  const { popularDestinationsData,isLoading } = props;
  const navigate = useNavigate();

  const onPopularDestincationCardClick = (city) => {
    navigate('/hotels', {
      state: {
        city: city.toString().toLowerCase(),
      },
    });
  };

  return (
    <div className="my-4">
      <h2 className="text-3xl font-medium text-slate-700 text-center">
        Book Homestay at Popular Destinations
      </h2>
      <div className="my-4 relative max-w-screen-xl mx-auto px-4"> {/* This div centers the content with margin */}
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: {
              slidesPerView: 1, // 1 item on small devices
            },
            768: {
              slidesPerView: 3, // 2 items on tablets
            },
            1024: {
              slidesPerView: 4, // 3 items on desktop
            },
          }}
          navigation={{
            prevEl: '.custom-prev-btn',  // Assign custom buttons to Swiper navigation
            nextEl: '.custom-next-btn',
          }}
          pagination={{
            clickable: true,
            el: '.custom-pagination',  // Custom pagination container
          }}
          onSwiper={(swiper) => {
            // You can access Swiper instance methods here if needed
            console.log(swiper);
          }}
        >
          {isLoading
            ? Array.from({ length: 5 }, (_, index) => (
                <SwiperSlide key={index}>
                  <ImageCardSkeleton />
                </SwiperSlide>
              ))
            : popularDestinationsData.map((city) => (
                <SwiperSlide key={city.id}>
                  <ImageCard
                    name={city.name}
                    imageUrl={city.image_url}
                    onPopularDestincationCardClick={onPopularDestincationCardClick}
                  />
                </SwiperSlide>
              ))}
        </Swiper>

        {/* Positioning the navigation buttons outside the Swiper area */}
                 <div className="absolute top-1/2 -translate-y-1/2 left-[-40px] z-10 hidden md:block">
           <button className="custom-prev-btn">
             <ArrowLeftIcon className="w-8 h-8 text-blue-600 hover:text-blue-900" />
           </button> {/* Left arrow */}
         </div>
         <div className="absolute top-1/2 -translate-y-1/2 right-[-40px] z-10 hidden md:block">
           <button className="custom-next-btn">
             <ArrowRightIcon className="w-8 h-8 text-blue-600 hover:text-blue-900" />
           </button> {/* Right arrow */}
         </div>

      </div>
      <div className="custom-pagination mt-6 text-center"></div>

    </div>
  );
};

export default PopularLocations;
