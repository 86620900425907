import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosClient from './Axios';

export const fetchPopularCity = async (queryParams) => {
  const response = await axiosClient.get('/web/popular-cities', {});
  return response.data.data.popular_cities;
};

export const fetchPopularHotels = async (queryParams) => {
    const response = await axiosClient.get('/web/featured-hotels', {});
    return response.data.data.featured_hotels.data;
};

export const fetchHotelDetails = async (hotelId) => {
    const response = await axiosClient.get(`/web/getHotelDetails/${hotelId}`, {});
    return response.data.hotel_details;
};

// Custom hook for fetching popular cities
export const useFetchPopularCityQuery = (queryParams) => {
  return useQuery({
    queryKey: ['popular-cities', queryParams], // Use queryKey as an array
    queryFn: () => fetchPopularCity(queryParams), // Use queryFn to call the fetch function
    enabled: !!queryParams,
    staleTime: 24 * 60 * 60 * 1000 
  });
};

export const useFetchPopularHotelsQuery = (queryParams) => {
    return useQuery({
      queryKey: ['featured-hotels', queryParams], // Unique query key for hotel fetching
      queryFn: () => fetchPopularHotels(queryParams), // Fetch function for hotels
      enabled: !!queryParams, // Enable query only if queryParams are present
      staleTime: 1 * 60 * 60 * 1000, // Cache data for 24 hours
    });
};
  
export const useFetchHotelDetailsQuery = (hotelId) => {
    return useQuery({
      queryKey: ['hotel-details', hotelId], // Unique query key for hotel details
      queryFn: () => fetchHotelDetails(hotelId), // Fetch function for hotel details
      enabled: !!hotelId, // Enable query only if hotelId is present
      staleTime: 1 * 60 * 60 * 1000, // Cache data for 24 hours
    });
  };