import React from 'react'

export default function Privacy() {
  return (
    <>
      <h1 className='text-center text-4xl text-brand my-5'>Privacy Policy</h1>

      <div className="container px-4 py-8">

      <h1 className="text-2xl font-extrabold mb-2">What information do we collect?</h1>
      <p className="text-lg mb-8">
      We gather data from you when you register on our site, submit a request, buy any services, react to an overview, or round out a structure. At the point when requesting any assistance or enrolling on our site, as suitable, you might be approached to enter your: name, email address, or telephone number. You may, nonetheless, visit our site anonymously.
      </p>

      <h1 className="text-2xl font-extrabold mb-2">How do we protect your information?
      </h1>
      <p className="text-lg mb-8">
      All provided delicate/credit data is sent through Stripe.
      After an exchange, your private data (credit cards, social security numbers, financials, and so on) won't be put away on our workers.
      </p>


      <h1 className="text-2xl font-extrabold mb-2">Do we disclose any information to outside parties?
      </h1>
      <p className="text-lg mb-8">
      We don't sell, exchange, or in any case move to outside gatherings by and by recognizable data. This does exclude confided in outsiders who help us in working our site, leading our business, or adjusting you, since those gatherings consent to keep this data private. We may likewise deliver your data when we accept discharge is suitable to follow the law, implement our site strategies, or ensure our own or others' rights, property, or wellbeing.
      </p>

      <h1 className="text-2xl font-extrabold mb-2">
        Children's Online Privacy Protection Act Compliance
      </h1>
      <p className="text-lg mb-8">
      We are consistent with the prerequisites of COPPA (Children's Online Privacy Protection Act), we don't gather any data from anybody under 13 years old. Our site, items, and administrations are completely coordinated to individuals who are in any event 13 years of age or more established.
      </p>

      <h1 className="text-2xl font-extrabold mb-2">
      Changes to our Privacy Policy
      </h1>
      <p className="text-lg mb-8">
      If we decide to change our privacy policy, we will post those changes on this page.
       </p>

      <h1 className="text-2xl font-extrabold mb-2">
      How long we retain your information?
      </h1>
      <p className="text-lg mb-8">
      At the point when you register for our site, we cycle and keep your information we have about you however long you don't erase the record or withdraw yourself (subject to laws and guidelines).
      </p>

      <h1 className="text-2xl font-extrabold mb-2">
      What we don’t do with your data
      </h1>
      <p className="text-lg mb-8">
      We don't and will never share, unveil, sell, or in any case give your information to different organizations for the promoting of their items or administrations.
      </p>

      </div>
    </>
  )
}
