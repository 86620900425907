import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import apiService from "services/Axios";

const RegisterHomestay = () => {
  const formRef = useRef();

  const [stateData,setStateData]=useState([]);
  const [cityData,setCityData]=useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(()=>{

    const fetchState=async()=>{

        try {
            
            const response=await apiService.get('/web/get-states',{country_id:98});

            if(response.data.data && response.data.data.states){
                setStateData(response.data.data.states);
            }

        } catch (error) {
            
            console.log(error)

        }

    }

    fetchState();

  },[])

  useEffect(()=>{

    const fetchCity=async()=>{

        try {
            
            const response=await apiService.get('/web/get-cities',{state_id:selectedState});

            if(response.data.data && response.data.data.cities){
                setCityData(response.data.data.cities);
            }

        } catch (error) {
            
            console.log(error)

        }

    }

    fetchCity();

  },[selectedState])

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setCityData([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    const formData = new FormData(formRef.current);

    const data = Object.fromEntries(formData.entries());

    try {
            
        const response=await apiService.post('/web/registerHomeStay',data);

        setLoading(false);

        if(response.data.status){
            alert(response.data.message);
            event.target.reset();
        }else{
            alert(response.data.message);
        }


    } catch (error) {

        setLoading(false);
        console.log(error)

    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6">
      <div className="bg-white shadow-lg rounded-lg max-w-7xl w-full grid lg:grid-cols-2">
        <div className="bg-white-100 p-10">
          <h2 className="text-3xl font-bold mb-6">What You Will Get</h2>
          <ul className="space-y-8">
            <li className="flex items-start">
              <div className="text-green-600 text-3xl mr-4">💵</div>
              <div>
                <h3 className="text-lg font-semibold">You Will Earn Money</h3>
                <p className="text-gray-600">
                  Maximize your earnings with our advanced financial tools, tailored to ensure your homestay’s financial success.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="text-green-600 text-3xl mr-4">🔧</div>
              <div>
                <h3 className="text-lg font-semibold">Easy to Handle</h3>
                <p className="text-gray-600">
                  Effortlessly manage your homestay with our user-friendly platform, offering a seamless interface and simplified management tools.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="text-green-600 text-3xl mr-4">💳</div>
              <div>
                <h3 className="text-lg font-semibold">Monthly Bill Payment System</h3>
                <p className="text-gray-600">
                  Simplify your monthly bill payments with our efficient system, making financial management easy and hassle-free.
                </p>
              </div>
            </li>


            <li className="flex items-start">
              <div className="text-green-600 text-3xl mr-4">💳</div>
              <div>
                <h3 className="text-lg font-semibold">
                Strong Reporting System
                </h3>
                <p className="text-gray-600">
                Unlock valuable insights with our powerful reporting system, offering detailed analytics and real-time data to optimize your homestay operations.
                </p>
              </div>
            </li>

            <li className="flex items-start">
              <div className="text-green-600 text-3xl mr-4">🔧</div>
              <div>
                <h3 className="text-lg font-semibold">Easy to Handle</h3>
                <p className="text-gray-600">
                  Effortlessly manage your homestay with our user-friendly platform, offering a seamless interface and simplified management tools.
                </p>
              </div>
            </li>

          </ul>
        </div>

        <div className="p-10 bg-white">
          <h2 className="text-3xl font-extrabold text-brand">Send Request To Be A Host</h2>

          <p className="mb-4">
            By sending request you can be an host and after approved your request you can maintain your own homestay.
          </p>
          <Spin spinning={loading}>
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Homestay Name</label>
                <input
                  type="text"
                  name="hotel_name"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Enter your homestay name"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Category</label>
                <select name="homestay_category" required className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
                  <option value="" disabled>Select One</option>
                  <option value="budget_homestay">Budget Homestay</option>
                  <option value="premium_homestay">Premium Homestay</option>
                  <option value="luxury_homestay">Luxury Homestay</option>
                </select>
              </div>

            </div>


              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Country</label>
                <select name="country" required className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
                  <option value="98">India</option>
                </select>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">State</label>
                <select name="state" 
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                onChange={handleStateChange}
                value={selectedState}
                required
                >
                  <option value="">Select State</option>
                  {stateData.map((state, index) => (
                    <option key={index} value={state.id}> 
                      {state.name} 
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">District</label>
                <select name="city" required className="mt-1 block w-full p-2 border border-gray-300 rounded-md">
                  <option value=" ">Select District </option>
                  {cityData.map((city, index) => (
                    <option key={index} value={city.id}> 
                      {city.name} 
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Vendor First Name</label>
                <input
                  type="text"
                  name="firstname"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="First Name"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Vendor Last Name</label>
                <input
                  type="text"
                  name="lastname"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Last Name"
                  required
                />
              </div>

              <div className="mb-4">
                <input type="hidden" name="mobile_code" value="+91"/>

                <label className="block text-sm font-medium text-gray-700">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Mobile"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>

            </div>

            <div className="mb-4">

            <h2 className="font-1xl font-semibold">Accept</h2>
            <input type="checkbox" required name="accept"/> I hereby declare that the information provided in this form is true and accurate to the best of my knowledge.

            </div>

            <div className="flex justify-end mt-4">
              <button type="submit" className="bg-[#1f621c] text-white w-full px-4 py-2 rounded-md">
                Send Request
              </button>
            </div>

          </form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default RegisterHomestay;
