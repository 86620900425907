import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { networkAdapter } from 'services/NetworkAdapter';
import HotelDetailsViewCard from './components/hotel-details-view-card/HotelDetailsViewCard';
import HotelDetailsViewCardSkeleton from './components/hotel-details-view-card-skeleton/HotelDetailsViewCardSkeleton';
import apiService from 'services/Axios';
import { useFetchHotelDetailsQuery } from 'services/API';

/**
 * Represents the hotel details component.
 * @component
 * @returns {JSX.Element} The hotel details component.
 */
const HotelDetails = () => {
  const { hotelId } = useParams();
  // const [hotelDetails, setHotelDetails] = useState({
  //   isLoading: true,
  //   data: {},
  // });

  const { data:hotelDetails, error:errros, isLoading:hotelLoading } = useFetchHotelDetailsQuery(hotelId);

  // useEffect(() => {
  //   const fetchHotelDetails = async () => {
  //     const response = await apiService.get(`/web/getHotelDetails/${hotelId}`);
  //     setHotelDetails({
  //       isLoading: false,
  //       data: response.data.hotel_details,
  //     });
  //   };

  //   fetchHotelDetails();
  // }, [hotelId]);

  return (
    <>
      {hotelLoading ? (
        <HotelDetailsViewCardSkeleton />
      ) : (
        <HotelDetailsViewCard hotelDetails={hotelDetails} />
      )}
    </>
  );
};

export default HotelDetails;
