import { useState } from 'react';
import { Link } from 'react-router-dom';
import { networkAdapter } from 'services/NetworkAdapter';
import { useNavigate } from 'react-router-dom';
import Toast from 'components/ux/toast/Toast';
import { REGISTRATION_MESSAGES } from 'utils/constants';
import { Formik, Form, Field } from 'formik';
import Schemas from 'utils/validation-schemas';
import apiService from 'services/Axios';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { login } from 'features/authSlice';

/**
 * Register Component
 * Renders a registration form that allows new users to create an account.
 * It captures user input for personal information and credentials, submitting these to a registration endpoint.
 * Upon successful registration, the user is notified and redirected to the login page.
 */
const Register = () => {
  const navigate = useNavigate();
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [showToast, setShowToast] = useState(false);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const dismissError = () => {
    setShowToast(false); // Hide the toast
  };
  /**
   * Submits the registration form data to the server.
   * It performs an asynchronous operation to post the form data to a registration endpoint.
   * If registration is successful, a success message is displayed, and the user is redirected to the login page after a brief delay.
   * Otherwise, the user is informed of the failure.
   *
   * @param {Object} e - The event object generated by the form submission.
   */
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);

      console.log('Form submitted with values:', values);
  
      const response = await apiService.post('/register', values);
  
      setLoading(false);

      if (response && response.data.status === 'success') {
        dispatch(login({ user: response.data.data.user, token:response.data.data.access_token }));
        setToastMessage(REGISTRATION_MESSAGES.SUCCESS);
        setShowToast(true);
        navigate('/user-profile');
      } else {
        setErrors({ email: response.data.message.error[0] ?? '' });
        
        console.error('API errors:', response.data.message.error);
        
        setToastMessage(response.data.message.error[0]??'');
        setToastType('error');
        setShowToast(true);
      }
    } catch (error) {
      setLoading(false);
      setToastMessage('An unexpected error occurred.');
      setToastType('error');
      setShowToast(true);
    } finally {
      setSubmitting(false); 
    }
  };

  return (
    <>
      <div className="register__form">
      <Spin spinning={loading}>
        <div className="container mx-auto p-4 flex justify-center min-h-[600px] items-center">
          <Formik
            initialValues={{
              firstname: '',
              lastname: '',
              email: '',
              username:'',
              mobile: '',
              password: '',
              mobile_code:'91',
              country_code:"IN",
              country:"India"
            }}
            validationSchema={Schemas.signupSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="w-full max-w-lg p-4 shadow-md md:p-10">
                  <div className="mb-10 text-center">
                    <h2 className="text-3xl font-extrabold text-brand">
                      Join the Adventure!
                    </h2>
                    <p className="text-gray-500">
                      Create your account and start your journey with us
                    </p>
                  </div>
                  <div className="flex flex-wrap mb-6 -mx-3">
                    <div className="w-full px-3 mb-6 md:w-1/2 md:mb-0 relative">
                      <Field
                        name="firstname"
                        placeholder="First Name"
                        autoComplete="given-name"
                        className={`${errors.firstname && touched.firstname ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                      />
                    </div>
                    <div className="w-full px-3 md:w-1/2">
                      <Field
                        name="lastname"
                        placeholder="Last Name"
                        autoComplete="family-name"
                        className={`${errors.lastname && touched.lastname ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    <Field
                      name="email"
                      placeholder="Email"
                      autoComplete="email"
                      className={`${errors.email && touched.email ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                    />
                  </div>

                  <div className="mb-6">
                    <Field
                      name="username"
                      placeholder="Username"
                      autoComplete=""
                      className={`${errors.username && touched.username ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                    />
                  </div>

                  <div className="mb-6">
                    <Field
                      name="mobile"
                      placeholder="Mobile"
                      autoComplete="tel"
                      className={`${errors.mobile	 && touched.mobile ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                    />
                  </div>
                  <div className="mb-6">
                    <Field
                      name="password"
                      placeholder="Password"
                      autoComplete="new-password"
                      className={`${errors.password && touched.password ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                    />
                  </div>
                  <div className="mb-6">
                    <Field
                      name="password_confirmation"
                      placeholder="Confirm Password"
                      autoComplete="new-password"
                      className={`${errors.password_confirmation && touched.password_confirmation ? 'border-red-500' : ''} border block w-full px-4 py-3 mb leading-tight text-gray-700 bg-gray-200 rounded appearance-none focus:outline-none focus:bg-white`}
                    />
                  </div>
                  <div className="flex items-center w-full my-3">
                    <button
                      type="submit"
                      className="w-full px-4 py-2 font-bold text-white rounded bg-brand hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    >
                      Register
                    </button>
                  </div>
                  <Link
                    to="/login"
                    className="inline-block w-full text-lg text-center text-gray-500 align-baseline hover:text-blue-800"
                  >
                    Back to login
                  </Link>
                  {showToast && (
                    <Toast
                      type={toastType}
                      message={toastMessage}
                      dismissError={dismissError}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Spin>
      </div>
    </>
  );
};

export default Register;
